var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(" " + _vm._s(_vm.pageheading.toLocaleUpperCase()) + " ")]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _c("div", { attrs: { id: ".3" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "0rem",
                      "margin-bottom": "1rem",
                      display: "flex",
                      "justify-content": "flex-end",
                    },
                    attrs: { id: "buttongroup" },
                  },
                  [
                    _c("div", { staticClass: "level" }, [
                      _c("div", { staticClass: "level-left" }, [
                        _c("div", { staticClass: "field is-grouped" }, [
                          _c("div", { staticClass: "control" }, [
                            _c(
                              "a",
                              {
                                class: [
                                  "button is-accent",
                                  !_vm.$hasPermissions(
                                    _vm.clientSession,
                                    ["CARRIERS"],
                                    2
                                  )
                                    ? "disabled"
                                    : "",
                                ],
                              },
                              [
                                _vm._m(0),
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "has-text-white",
                                        attrs: {
                                          to: {
                                            name: !_vm.$hasPermissions(
                                              _vm.clientSession,
                                              ["CARRIERS"],
                                              2
                                            )
                                              ? ""
                                              : "CarrierManagementNewScreen",
                                            params: { carrier: {} },
                                          },
                                        },
                                      },
                                      [_vm._v(" Add Carrier ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "table",
                  {
                    ref: "table",
                    staticClass: "table is-striped is-bordered",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _vm._m(1),
                    _vm.carriers.length > 1
                      ? _c(
                          "tbody",
                          _vm._l(
                            _vm.carriers.filter(function (_carrier) {
                              return _carrier.code !== "BCC"
                            }),
                            function (carrier) {
                              return _c("tr", { key: carrier.id }, [
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "CarrierManagementEditScreen",
                                            params: {
                                              carrier: carrier,
                                              code: carrier.code,
                                            },
                                            query: { code: carrier.code },
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(carrier.name) + " ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v(_vm._s(carrier.code))]),
                                _c("td", [
                                  _c("div", { staticClass: "actions" }, [
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip.left-start",
                                            value: {
                                              content: "Edit Carrier",
                                              container: false,
                                              classes: ["tooltip"],
                                            },
                                            expression:
                                              "{ content: 'Edit Carrier', container: false, classes: ['tooltip'] }",
                                            modifiers: { "left-start": true },
                                          },
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.edit(carrier)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-pencil-alt",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip.left-start",
                                            value: {
                                              content: "Delete Carrier",
                                              container: false,
                                              classes: ["tooltip"],
                                            },
                                            expression:
                                              "{ content: 'Delete Carrier', container: false, classes: ['tooltip'] }",
                                            modifiers: { "left-start": true },
                                          },
                                        ],
                                        class: [
                                          "trash",
                                          !_vm.$hasPermissions(
                                            _vm.clientSession,
                                            ["CARRIERS"],
                                            2
                                          )
                                            ? "disabled"
                                            : "",
                                        ],
                                        attrs: {
                                          disabled: !_vm.$hasPermissions(
                                            _vm.clientSession,
                                            ["CARRIERS"],
                                            2
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.remove(carrier)
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "fa fa-trash" })]
                                    ),
                                  ]),
                                ]),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm.carriers.length === 1
                      ? _c("tbody", [_vm._m(2)])
                      : _vm._e(),
                  ]
                ),
                _vm.showDeleteModal
                  ? _c(
                      "div",
                      {
                        staticClass: "modal is-active",
                        attrs: { id: "delete-modal" },
                      },
                      [
                        _c("div", {
                          staticClass: "modal-background",
                          on: {
                            click: function ($event) {
                              _vm.showDeleteModal = false
                            },
                          },
                        }),
                        _c("div", { staticClass: "modal-card" }, [
                          _c(
                            "header",
                            { staticClass: "modal-card-head has-bg-danger" },
                            [
                              _vm.isDeletable
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "modal-card-title has-bg-danger",
                                    },
                                    [_vm._v("Confirm Delete")]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "modal-card-title has-bg-danger",
                                    },
                                    [_vm._v("Unable To Delete Carrier")]
                                  ),
                              _c("a", {
                                staticClass: "delete",
                                attrs: { "aria-label": "close" },
                                on: {
                                  click: function ($event) {
                                    _vm.showDeleteModal = false
                                  },
                                },
                              }),
                            ]
                          ),
                          _c("section", { staticClass: "modal-card-body" }, [
                            _vm.isDeletable
                              ? _c("div", { staticClass: "content" }, [
                                  _vm._v(' Type "'),
                                  _c("b", [_vm._v("delete")]),
                                  _vm._v(
                                    '" to confirm that you want to delete "' +
                                      _vm._s(_vm.selectedCarrier.name) +
                                      '" '
                                  ),
                                ])
                              : _c("div", { staticClass: "content" }, [
                                  _c("p", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.selectedCarrier.name)),
                                    ]),
                                    _vm._v(
                                      " cannot be deleted because it is currently in use by the following institutions: "
                                    ),
                                  ]),
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.selectedCarrier.institutions,
                                      function (institution) {
                                        return _c("li", { key: institution }, [
                                          _vm._v(_vm._s(institution.name)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                            _vm.isDeletable
                              ? _c("div", { staticClass: "field" }, [
                                  _c("div", { staticClass: "control" }, [
                                    _c("input", {
                                      directives: [
                                        { name: "focus", rawName: "v-focus" },
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.confirmationText,
                                          expression: "confirmationText",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      staticClass: "input",
                                      attrs: {
                                        type: "text",
                                        placeholder:
                                          "type `delete` and then click confirm",
                                      },
                                      domProps: { value: _vm.confirmationText },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                          return _vm.deleteIfConfirmed.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.confirmationText =
                                            $event.target.value.trim()
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                          _c("footer", { staticClass: "modal-card-foot" }, [
                            _vm.isDeletable
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "button is-danger",
                                    attrs: {
                                      disabled:
                                        _vm.confirmationText !== "delete",
                                    },
                                    on: { click: _vm.deleteIfConfirmed },
                                  },
                                  [_vm._m(3), _c("span", [_vm._v("Confirm")])]
                                )
                              : _vm._e(),
                            _c(
                              "a",
                              {
                                staticClass: "button",
                                on: {
                                  click: function ($event) {
                                    _vm.showDeleteModal = false
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-plus-square" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "cms", staticStyle: { width: "75%" } }, [
          _vm._v("Name"),
        ]),
        _c(
          "th",
          {
            staticClass: "cms",
            staticStyle: { width: "10%", "text-align": "left" },
          },
          [_vm._v("Short Name")]
        ),
        _c(
          "th",
          {
            staticClass: "cms",
            staticStyle: { width: "5%", "text-align": "center" },
          },
          [_vm._v("Actions")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "3" } }, [
        _vm._v(" No Carriers In System "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }